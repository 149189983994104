module.exports = {
    props: ['competitionRankLabels', 'applicationMethodLabels', 'athleteRanks', 'id', 'genderShortLabels', 'competitionTypeLabels', 'quotasAlgTypes', 'courses'],
    data: function() {
        return {
            step: 1,
            form: new TrustyForm({
                id: null,
                name: '',
                city_id: '',
                facility_address: '',
                facility_name: '',
                lane_min: 1,
                lane_max: 8,
                course: 1,
                competition_type: 0,
                competition_rank: 1,
                application_method: 1,
                need_register_address: 0,
                need_general_teams: 0,
                date_from: '',
                date_to: '',
                check_in_at: '',
                departure_at: '',
                is_use_quotas: 0,
                quotas_alg_type: 0,
                participant_count: '',
                paid_participant_count: '',
                free_participant_count: '',
                reserve_participant_count: '',
                regulation_doc_file: '',
                proposal_from: '',
                proposal_to: '',
                municipality_agreement_doc_file: '',
                safety_plan_doc_file: '',
                medical_plan_doc_file: '',
                competition_age_groups: [],
                sessions: [],
                draft: 0,
                can_edit: true
            }),
            cities: [],
            ageGroups: [],
            swimPrograms: [],
            swimProgramsByKey: {},
            sessionDate: '',
            constants: require('../constants'),
        }
    },
    computed: {
        /**
         * Модель города
         */
        city: {
            get: function() {
                let filtered = this.cities.filter(city => city.id == this.form.city_id);
                if (filtered.length > 0) {
                    return filtered[0];
                }
                return null;
            },
            set: function(value) {
                this.form.city_id = value.id;
            }
        },
        /**
         * Доступные возрастные группы с учетом произвольных
         */
        extendedAgeGroups: function() {
            let ageGroups = [...this.ageGroups];
            ageGroups.push({id: -3, name: "Произвольный возраст, микст", gender: "X"});
            ageGroups.push({id: -2, name: "Произвольный возраст, мужчины", gender: "M"});
            ageGroups.push({id: -1, name: "Произвольный возраст, женщины", gender: "F"});
            return ageGroups;
        },
        /**
         * Можем ли отобразить кнопку "Сохранить черновик"
         * @returns {boolean|boolean}
         */
        canShowSaveAsDraft: function() {
            return this.form.id && this.form.status === 0;
        },
        /**
         * Замечание об участии в соревновании в зависимости от типа и уровня
         * @returns {string}
         */
        competitionNoticeMessage: function() {
            if (this.form.competition_type == 0) {
                return 'Участвовать могут все желающие клубы и муниципальные образования';
            }
            let competitionRank = parseInt(this.form.competition_rank);
            switch (competitionRank) {
                case this.constants.COMPETITION_RANK_CITY:
                    return 'Участвовать могут все желающие клубы текущего города';
                case this.constants.COMPETITION_RANK_MUNICIPALITY:
                    return 'Участвовать могут все желающие клубы текущего муниципального образования';
                case this.constants.COMPETITION_RANK_REGION:
                    return 'Участвовать могут все желающие клубы текущего края';
            }

            return 'Участвовать могут все желающие клубы';
        },
        /**
         * Вывести дату проведения соревнования
         * @returns {string}
         */
        formattedDate: function() {
            return this.getFormattedCompetitionDates(this.form.date_from, this.form.date_to);
        },

        /**
         * Вычисляем все дублирующиеся плавательные программы в соревновании
         * @returns {[]}
         */
        swimProgramDuplicates: function() {
            let duplicates = [];
            let indexedPrograms = [];

            for(let i = 0; i < this.form.sessions.length; i++) {
                for (let j = 0; j < this.form.sessions[i].swim_programs.length; j++) {
                    let key = this.getSwimProgramKey(this.form.sessions[i].swim_programs[j]);
                    if (!indexedPrograms[key]) {
                        indexedPrograms[key] = 0;
                    }
                    indexedPrograms[key]++;
                }
            }

            for (let key in indexedPrograms)  {
                if (indexedPrograms[key] > 1) {
                    duplicates.push(key);
                }
            }

            return duplicates;
        },
    },
    methods: {
        /**
         * Создание соревнования на 1 этапе
         */
        create: function () {
            let self = this;
            Trusty.postWithFiles('/competitions', this.form, [
                'regulation_doc_file',
                'municipality_agreement_doc_file',
                'safety_plan_doc_file',
                'medical_plan_doc_file',
            ])
                .then(response => {
                    if (self.form.draft) {
                        location.href = '/my-competitions';
                    } else {
                        $.extend(true, self.form, response);
                        self.form.competition_age_groups = response.age_groups;
                        self.step = 2;
                    }
                })
                .catch(error => {
                    self.form.draft = 0;
                })
        },
        /**
         * Сохраняем как черновик
         */
        createAsDraft: function() {
            this.form.draft = 1;
            this.create();
        },
        /**
         * Сохраняем соревновательные дни
         */
        setupSessions: function() {
            let self = this;
            Trusty.post('/competitions/setup-sessions/' + this.form.id + '?mode=' + (this.id ? 'edit' : 'create'), this.form)
                .then(response => {
                    if (response.system_request && response.system_request.id) {
                        location.href = '/competitions/requests/' + response.system_request.id;
                    } else {
                        location.href = '/my-competitions';
                    }
                    self.form.draft = 0;
                    return;
                })
                .catch(error => {
                    console.warn('Ошибка при настройке соревновательных дней');
                    self.form.draft = 0;
                })
        },
        /**
         * Сохранение сессий как черновик
         */
        setupSessionsAsDraft: function() {
            this.form.draft = 1;
            this.setupSessions();
        },
        /**
         * Загружаем данные о соревновании
         */
        loadCompetition: function() {
            axios.get('/competitions/fetch/' + this.id)
                .then(response => {
                    $.extend(true, this.form, response.data);
                    this.form.competition_age_groups = response.data.age_groups;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке соревнования', error);
                })
        },
        /**
         * Загружаем список городов
         */
        loadCities: function() {
            axios.get('/dictionaries/cities/all')
                .then(response => {
                    this.cities = response.data;
                })
                .catch(error => {});
        },
        /**
         * Загружаем список возрастных групп
         */
        loadAgeGroups: function() {
            axios.get('/dictionaries/age-groups/all')
                .then(response => {
                    this.ageGroups = response.data;
                    if (this.form.competition_age_groups.length == 0) {
                        this.addCompetitionAgeGroup();
                    }
                })
                .catch(error => {});
        },
        /**
         * Загружаем список видов программ
         */
        loadSwimPrograms: function() {
            axios.get('/dictionaries/swim-programs/all')
                .then(response => {
                    this.swimPrograms = response.data;
                    let self = this;
                    response.data.forEach((item) => {
                        self.swimProgramsByKey[item.id] = item;
                    })
                })
                .catch(error => {});
        },
        /**
         * Обработчик смены файла регламента
         * @param fileData
         */
        onChangeRegulationDocFile: function(fileData) {
            this.form.regulation_doc_file = fileData;
        },
        /**
         * Обработчик смены файла согласования муниципального образования
         * @param fileData
         */
        onChangeMunicipalityAgreementDocFile: function(fileData) {
            this.form.municipality_agreement_doc_file = fileData;
        },
        /**
         * Обработчик смены файла утвержденного плана безопасности
         * @param fileData
         */
        onChangeSafetyPlanDocFile: function(fileData) {
            this.form.safety_plan_doc_file = fileData;
        },
        /**
         * Обработчик смены файла утвержденного плана безопасности
         * @param fileData
         */
        onChangeMedicalPlanDocFile: function(fileData) {
            this.form.medical_plan_doc_file = fileData;
        },
        /**
         * Добавляем возрастную группу в соревнование
         */
        addCompetitionAgeGroup: function() {
            let competitionAgeGroup = {
                age_group_id: this.ageGroups.length ? this.ageGroups[0].id : '',
                athlete_rank_id: '',
                gender: this.ageGroups.length ? this.ageGroups[0].gender : '',
            };
            this.form.competition_age_groups.push(competitionAgeGroup);
        },
        /**
         * Удаляем возрастную группу
         * @param index
         */
        removeCompetitionAgeGroup: function(index) {
            this.form.competition_age_groups.splice(index, 1);
        },
        /**
         * Проставляем значение из справочника для возрастной группы
         * @param item
         * @param index
         */
        setCompetitionAgeGroupValue: function(item, index) {
            let currentAgeGroup = this.form.competition_age_groups[index];
            if (!item) {
                currentAgeGroup.age_group_id = '';
                currentAgeGroup.gender = '';
            } else if (item.id > 0) {
                currentAgeGroup.age_group_id = item.id;
                currentAgeGroup.gender = item.gender;
            } else {
                currentAgeGroup.age_group_id = '';
                currentAgeGroup.gender = item.gender;
            }
            Vue.set(this.form.competition_age_groups, index, currentAgeGroup);
        },
        /**
         * Получаем значение для возрастной группы
         * @param index
         * @returns {null|*}
         */
        getCompetitionAgeGroupValue: function(index) {
            let currentAgeGroup = this.form.competition_age_groups[index];
            var filtered = this.extendedAgeGroups.filter(item => (item.id == currentAgeGroup.age_group_id || item.id < 0 && item.gender == currentAgeGroup.gender));
            if (filtered.length) {
                return filtered[0];
            }
            return null;
        },
        /**
         * Проставляем мин возраст для произвольной группы
         * @param value
         * @param index
         */
        setCompetitionAgeGroupMin: function(value, index) {
            Vue.set(this.form.competition_age_groups[index], 'age_min', value);
        },
        /**
         * Получаем мин возраст для произвольной группы
         * @param index
         * @returns {string}
         */
        getCompetitionAgeGroupMin: function(index) {
            return this.form.competition_age_groups[index].age_min ? this.form.competition_age_groups[index].age_min : '';
        },
        /**
         * Проставляем max возраст для произвольной группы
         * @param value
         * @param index
         */
        setCompetitionAgeGroupMax: function(value, index) {
            Vue.set(this.form.competition_age_groups[index], 'age_max', value);
        },
        /**
         * Получаем max возраст для произвольной группы
         * @param index
         * @returns {string}
         */
        getCompetitionAgeGroupMax: function(index) {
            return this.form.competition_age_groups[index].age_max ? this.form.competition_age_groups[index].age_max : '';
        },
        /**
         * Проставляем разряд для возрастной группы
         * @param item
         * @param index
         */
        setCompetitionAgeGroupAthleteRank: function(item, index) {
            let currentAgeGroup = this.form.competition_age_groups[index];
            currentAgeGroup.athlete_rank_id = item ? item.value : '';
            Vue.set(this.form.competition_age_groups, index, currentAgeGroup);
        },
        /**
         * Получаем разряд для возрастной группы
         * @param index
         * @returns {null|*}
         */
        getCompetitionAgeGroupAthleteRank: function(index) {
            let currentAgeGroup = this.form.competition_age_groups[index];
            var filtered = this.athleteRanks.filter(item => item.value == currentAgeGroup.athlete_rank_id);
            if (filtered.length) {
                return filtered[0];
            }
            return null;
        },
        /**
         * Добавление нового соревновательного дня
         */
        addCompetitionSession: function() {
            if (this.sessionDate) {
                this.form.sessions.push({
                    session_date : this.sessionDate,
                    swim_programs: []
                });
                this.sessionDate = '';
            }
        },
        /**
         * Удаление соревновательного дня
         */
        removeCompetitionSession: function(index) {
            this.form.sessions.splice(index, 1);
        },
        /**
         * Добавляем программу соревнования к сессии
         * @param sessionIndex
         */
        addSwimProgramToSession: function(sessionIndex) {
            let self = this;
            this.$refs.swimProgramSetup.init({
                    mode: 'create'
                },
                function (params) {
                    let currentSession = self.form.sessions[sessionIndex];
                    currentSession.swim_programs.push(params);
                    Vue.set(self.form.sessions, sessionIndex, currentSession);
                }
            );
        },
        /**
         * Редактируем вид программы
         * @param sessionIndex
         * @param swimProgramIndex
         */
        editCompetitionSwimProgram: function(sessionIndex, swimProgramIndex) {
            let self = this;
            this.$refs.swimProgramSetup.init({
                    mode: 'edit',
                    ...self.form.sessions[sessionIndex].swim_programs[swimProgramIndex]
                },
                function (params) {
                    let currentSession = self.form.sessions[sessionIndex];
                    let currentSwimProgram = self.form.sessions[sessionIndex].swim_programs[swimProgramIndex];
                    $.extend(true, currentSwimProgram, params);
                    currentSwimProgram.age_groups = params.age_groups;
                    currentSession.swim_programs[swimProgramIndex] = currentSwimProgram;
                    Vue.set(self.form.sessions, sessionIndex, currentSession);
                }
            );
        },
        /**
         * Удаляем вид программы
         * @param sessionIndex
         * @param swimProgramIndex
         */
        removeCompetitionSwimProgram: function(sessionIndex, swimProgramIndex) {
            let currentSession = this.form.sessions[sessionIndex];
            currentSession.swim_programs.splice(swimProgramIndex, 1);
            Vue.set(this.form.sessions, sessionIndex, currentSession);
        },
        /**
         * @brief Получение количества возрастных групп у определенной сессии
         * @param genderValue
         * @param isAllAgeGroupsValue
         * @param ageGroupsIds
         * @returns {number}
         */
        getCountAgeGroups: function(genderValue, isAllAgeGroupsValue, ageGroupsIds) {
            let objs = this.availableAgeGroups(genderValue, isAllAgeGroupsValue, ageGroupsIds)
            return objs.length;
        },
        /**
         * @brief Получение массива возрастных групп у определенной сессии
         * @param genderValue
         * @param isAllAgeGroupsValue
         * @param ageGroupsIds
         * @returns {methods.selectedAgeGroups|[]|*[]|*}
         */
        availableAgeGroups: function(genderValue, isAllAgeGroupsValue, ageGroupsIds) {
            if (!this.form.competition_age_groups) {
                return [];
            }
            if  (isAllAgeGroupsValue == 1) {
                return this.form.competition_age_groups.filter(item => item.gender == genderValue);
            } else if (ageGroupsIds.length > 0) {
                let dataAgeGroups = this.form.competition_age_groups;
                let objAgeGroups = [];
                dataAgeGroups.forEach(function (ageGroup) {
                    if (ageGroupsIds.includes(ageGroup.id)) {
                        objAgeGroups.push(ageGroup);
                    }
                });
                return objAgeGroups;
            }
            return [];
        },
        /**
         * Формируем наименование возрастной группы соревнования
         * @param competitionAgeGroup
         * @returns {*}
         */
        getAgeGroupLabel: function(competitionAgeGroup) {
            //if (competitionAgeGroup.age_group_id && this.ageGroups[competitionAgeGroup.age_group_id]) {
            if (competitionAgeGroup.age_group_id) {
                // let filtered = this.ageGroups.filter(item => item.id == competitionAgeGroup.age_group_id);
                // if (filtered.length) {
                //     return filtered[0].name;
                // }
                if (typeof(competitionAgeGroup.age_min) === 'undefined' || typeof(competitionAgeGroup.age_max) === 'undefined') {
                    let filtered = this.ageGroups.filter(item => item.id == competitionAgeGroup.age_group_id);
                    if (filtered.length) {
                        competitionAgeGroup.age_max = filtered[0].age_max;
                        competitionAgeGroup.age_min = filtered[0].age_min;
                    }
                }
            }
            return Vue.filter('ageGroupTitle')(competitionAgeGroup);
        },
        /**
         * Даты возможных сессий
         */
        disabledSessionDates: function(date) {
            if (this.form.date_from && date < moment(this.form.date_from).toDate()) {
                return true;
            }
            if (this.form.date_to && date > moment(this.form.date_to).toDate()) {
                return true;
            }
            let formatted = moment(date).format('YYYY-MM-DD');
            return this.form.sessions.filter(item => item.session_date == formatted).length > 0;
        },
        /**
         * Значение для компонента драггабл
         * @param index
         */
        getDraggableSwimProgram: function(index) {
            return this.form.sessions[index].swim_programs;
        },
        /**
         * Проставление значения для компонента драггабл
         * @param $event
         * @param index
         */
        setDraggableSwimProgram: function($event, index) {
            let currentSession = this.form.sessions[index];
            currentSession.swim_programs = $event;
            Vue.set(this.form.sessions, index, currentSession);
        },
        /**
         * Пересчитываем платные места
         */
        recalculateParticipants: function () {
            if (this.form.quotas_alg_type == this.constants.COMPETITION_ALG_TYPE_DEFAULT) {
                this.form.paid_participant_count = Math.max(0,
                    parseInt(this.form.participant_count) - parseInt(this.form.reserve_participant_count) - parseInt(this.form.free_participant_count)
                );
            } else {
                this.form.paid_participant_count = Math.max(0,
                    parseInt(this.form.participant_count) - parseInt(this.form.reserve_participant_count)
                );
            }

        },
        /**
         * Обработчик смены типа соревнования
         */
        onChangeCompetitionType: function () {
            if (this.form.competition_type == 0) {
                this.form.need_register_address = 0;
            }
        },
        /**
         * Обработчик смены ранга соревнований
         */
        onChangeCompetitionRank: function () {
            if (this.form.competition_rank == 1) {
                this.form.need_general_teams = 0;
            }
        },
        /**
         * Обработчик смены необходимости сборной команды
         */
        onChangeNeedGeneralTeams: function() {
            if (this.form.need_general_teams) {
                this.form.is_use_quotas = 0;
                this.form.participant_count = '';
                this.form.free_participant_count = '';
                this.form.paid_participant_count = '';
                this.form.reserve_participant_count = '';
            }
        },
        /**
         * Получить ключ по id плавательной программе и полу программы
         * @param swimProgram
         * @returns {*}
         */
        getSwimProgramKey: function(swimProgram) {
            return swimProgram.gender + swimProgram.swim_program_id;
        },
        /**
         * Проверяем, нет ли в соревновании таких же плавательных программ для того же пола
         * @param swimProgram
         * @returns {boolean}
         */
        checkProgramHasDuplicates: function(swimProgram) {
            let key = this.getSwimProgramKey(swimProgram);
            return this.swimProgramDuplicates.indexOf(key) !==-1;
        },
    }
}
