Vue.component('competition-request-view', {
    mixins: [require('../mixins/dateFormatter'), require('../mixins/changeCompetitionChecker'), require('../mixins/form')],
    props: ['id', 'emptyLabel', 'genderShortLabels', 'competitionTypeLabels', 'competitionRankLabels', 'applicationMethodLabels', 'athleteRanks', 'courses'],
    data: function() {
        return {
            processing: false,
            deleting: false,
            restoring: false,
            data: {},
            approving: false
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/competitions/requests',
            backTitle: 'К заявкам соревнований',
            title: 'Заявка на проведение соревнования'
        });
        this.loadData();
        this.loadAgeGroups();
        this.loadSwimPrograms();
    },
    computed: {
        /**
         * Отклоненная заявка
         * @param row
         * @returns {boolean}
         */
        isStatusDeclined: function() {
            return this.data.request_status == 3;
        },
        /**
         * Ожидает одобрения
         * @param row
         * @returns {boolean}
         */
        isStatusCreated: function() {
            return this.data.request_status == 1;
        },
        /**
         * Является ли входящей заявкой
         */
        isModeInbox: function() {
            return this.data.mode == 'inbox';
        }
    },
    methods: {
        /**
         * Загрузка данных заявки
         */
        loadData: function() {
            this.processing = true;
            axios.get('/competitions/requests/fetch/' + this.id)
                .then(response => {
                    this.data = response.data;
                    this.processing = false;
                })
                .catch(error => {
                    console.warn('Ошибка при загрузке данных заявки на соревнование', error);
                    this.processing = false;
                })
        },
        /**
         * Формируем наименование возрастной группы соревнования
         * @param competitionAgeGroup
         * @returns {*}
         */
        getAgeGroupLabel: function(competitionAgeGroup) {
            if (competitionAgeGroup.age_group_id) {
                return this.ageGroups[competitionAgeGroup.age_group_id - 1].name;
            }
            return Vue.filter('ageGroupTitle')(competitionAgeGroup);
        },
        /**
         * Подтверждение одобрения
         * @param row
         */
        confirmApprove: function() {
            this.$refs.confirmModal.init({
                title: this.data.title,
                confirmText: 'Вы действительно хотите одобрить данную заявку?',
                confirmButtonTitle: 'Одобрить',
                confirmButtonClass: 'btn-primary',
                confirmCallback: this.approve
            })
        },
        /**
         * Одобрение заявки
         */
        approve: function() {
            this.approving = true;
            axios.post('/system-requests/approve', {id: this.id})
                .then(response => {
                    this.approving = false;
                    location.href = '/competitions/requests';
                })
                .catch(error => {
                    console.warn('Ошибка при одобрении заявки', error);
                })
        },
        /**
         * Подтверждение удаления
         * @param row
         */
        confirmDecline: function() {
            this.data.id = this.id;
            let self = this;
            this.$refs.declineModal.init(this.data, function(responseData) {
                Bus.$emit('pushFlashAlert', {
                    type: 'success',
                    message: 'Заявка успешно отклонена'
                });
                Bus.$emit('reloadMenu');
                self.data = responseData;
            });
        },
        /**
         * @brief Получение количества возрастных групп у определенной сессии
         * @param genderValue
         * @param isAllAgeGroupsValue
         * @param ageGroupsMas
         * @returns {number}
         */
        getCountAgeGroups: function(genderValue, isAllAgeGroupsValue, ageGroupsMas) {
            let objs = this.availableAgeGroups(genderValue, isAllAgeGroupsValue, ageGroupsMas)
            return objs.length;
        },
        /**
         * @brief Получение массива возрастных групп у определенной сессии
         * @param genderValue
         * @param isAllAgeGroupsValue
         * @param ageGroupsMas
         * @returns {methods.selectedAgeGroups|[]|*[]|*}
         */
        availableAgeGroups: function(genderValue, isAllAgeGroupsValue, ageGroupsMas) {
            if (!this.data.request_object.age_groups) {
                return [];
            }
            if  (isAllAgeGroupsValue == 1) {
                return this.data.request_object.age_groups.filter(item => item.gender == genderValue);
            } else if (ageGroupsMas.length > 0) {
                let dataAgeGroups = this.data.request_object.age_groups;
                let objAgeGroups = [];
                dataAgeGroups.forEach(function (ageGroup) {
                    if (ageGroupsMas.includes(ageGroup.id)) {
                        objAgeGroups.push(ageGroup);
                    }
                });
                return objAgeGroups;
            }
            return [];
        }
    }
});
