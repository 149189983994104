Vue.component('fina-point-group-import-form', {
    props: ['title', 'courses'],
    data: function() {
        return {
            form: new TrustyForm({
                id: '',
                name: '',
                course: '',
                valid_from: '',
                valid_until: '',
                points_file: ''
            }),
        }
    },
    mounted: function() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            mode: 'detail',
            backLink: '/competitions/fina-points',
            backTitle: 'К очкам FINA',
            title: this.title
        });
    },
    computed: {
        /**
         * Урл для отправки запроса
         * @returns {string}
         */
        sendUrl: function () {
            return '/competitions/fina-points';
        }
    },
    methods: {
        /**
         * Обработчик смены файла с данными
         * @param fileData
         */
        onChangePointsFile: function(fileData) {
            this.form.points_file = fileData;
        },
        /**
         * Отправка формы на сервер
         */
        send: function () {
            Trusty.postWithFiles(this.sendUrl, this.form,[
                'points_file',
            ])
                .then(response => {
                    location.href = '/competitions/fina-points';
                })
                .catch(error => {

                })
        },
    }
});
