Vue.component('fina-point-group-list', {
    props: ['courseMaps'],
    data: function () {
        return {
            currentRow: null
        }
    },
    mounted() {
        Bus.$emit('changeMenuState', {
            itemCode: 'competitions',
            childCode: 'fina-point-groups'
        });
    },
    methods:{
        /**
         * @brief Форматированный вывод типа бассейна
         * @param value
         * @param row
         * @returns {*}
         */
        courseTypeFormat: function(value, row) {
            return this.courseMaps[value];
        },
        /**
         * Подтверждение удаления группы очков FINA
         */
        confirmDelete: function(row) {
            this.currentRow = row;
            this.$refs.confirmModal.init({
                title: 'Перенос группы очков FINA в архив',
                confirmText: 'Вы действительно хотите перенести в архив данную группу очков FINA?',
                confirmButtonTitle: 'Перенeсти',
                confirmButtonClass: 'btn-danger',
                confirmCallback: this.deleteFinaPointGroup
            })
        },
        /**
         * Удаление группы очков FINA
         */
        deleteFinaPointGroup: function() {
            this.$refs.finaPointGroupGrid.setLoading(true);
            let self = this;
            axios.delete('/competitions/fina-points/' + this.currentRow.id)
                .then(
                    response => {
                        self.$refs.finaPointGroupGrid.reloadData();
                    }
                )
                .catch(error => {
                    console.warn('Ошибка при удалении очков FINA', error);
                    self.$refs.finaPointGroupGrid.setLoading(false);
                })
        },
    }
});
